// Returns false if a user has explicitly disabled keyboard shortcuts.
export const keyboardShortcutsEnabled = () => {
  const keyboardShortcutsPreference = document.querySelector<HTMLMetaElement>(
    'meta[name=keyboard-shortcuts-preference]'
  )
  if (keyboardShortcutsPreference) {
    return keyboardShortcutsPreference.content === 'all'
  }
  return true
}
